import { env } from './_helpers/environment';

const dev = {
    stripePublicKey: 'pk_test_u9MQdjuwQOO92KqJ9LSOGdss',
    reCaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // https://developers.google.com/recaptcha/docs/faq
    apiHost: 'http://localhost:5000',
    uploadBucket: 'https://s3.ca-central-1.amazonaws.com/paco-s3-askmed-uploads-dev-bucket-cac1',
    ipApi: 'https://api.ipdata.co?api-key=9112f3f045d8eda991ed2223fc31d40bf07f3c73796513e177c5f960',
    payPalClientId: 'AfasSsxI0Kz8-3SIaYst3CB5GGAt5qEbwihriay92Spl0ymhIUf42BKv26g0KHtqxIpFN7IlNSIHxJIn',
    isIp: true,
    southVanHost: 'http://localhost:6060',
    southVanApi:'http://localhost:3000',
    appointmentHost: 'http://localhost:8080',
    southVanSecret: 'cXpMnLhQ*7#qX1i',
};

const auto = dev;

const test = {
    stripePublicKey: 'pk_test_u9MQdjuwQOO92KqJ9LSOGdss',
    reCaptchaSiteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    apiHost: 'https://api.test.instore.askmedication.com',
    uploadBucket: 'https://s3.ca-central-1.amazonaws.com/ne-askmed-test-app-askmed-backend-uploads-bucket-cac1',
    ipApi: 'https://api.ipdata.co?api-key=9112f3f045d8eda991ed2223fc31d40bf07f3c73796513e177c5f960',
    payPalClientId: 'AfasSsxI0Kz8-3SIaYst3CB5GGAt5qEbwihriay92Spl0ymhIUf42BKv26g0KHtqxIpFN7IlNSIHxJIn',
    isIp: true,
    southVanHost:'https://test.instoremed.com',
    southVanApi:'https://api.test.instoremed.com',
    appointmentHost: 'https://appointment.test.instoremed.com/',
    southVanSecret: 'cXpMnLhQ*7#qX1i',
};

const prod = {
    stripePublicKey: 'pk_live_aA3Iz3Kp2JlgmwIiemE226XK',
    reCaptchaSiteKey: '6LfBfLkZAAAAALRhwx1-PJafQd-DK58IE-TKxFVf',
    apiHost: 'https://api.instore.askmedication.com',
    uploadBucket: 'https://s3.ca-central-1.amazonaws.com/ne-askmed-prod-app-askmed-backend-uploads-bucket-cac1',
    ipApi: 'https://api.ipdata.co?api-key=9112f3f045d8eda991ed2223fc31d40bf07f3c73796513e177c5f960',
    payPalClientId: 'AdRyR1BlpW4L2GzstknZ9fsQzKvAH7EKqizvb-pAMy0lGfgEU-ikDTdLTOhwkWCKj0NddbhXjT_KYstu',
    isIp: true,
    southVanHost:'https://instoremed.com',
    southVanApi:'https://api.instoremed.com',
    appointmentHost: 'https://appointment.instoremed.com/login',
    southVanSecret: 'cXpMnLhQ*7#qX1i',

};

const config = {
    dev, auto, test, prod
}

export default config[env()];
