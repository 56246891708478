import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';



class Blog extends React.Component{
    constructor(props){
        super(props);
    }

    renderItem = (title, text, to, date) => {
        return(
            <section className="title1 cid-s8pHUssV0f blog-list__item" id="title1-19">
                <div class="container align-center">
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style={{textAlign: "left"}}>
                            <div class="wrap d-flex flex-column align-items-center">
                                    <h5 
                                        class="mbr-section-title align-center mbr-fonts-style display-6 blog-list__item__title"
                                        style={{color: "#4f87fb"}}
                                    >{title}</h5>
                                    <p 
                                        class={"mbr-text align-center mbr-fonts-style display-4 blog-list__item__content"}
                                        style={{lineHeight: "1.8"}}
                                        
                                    >{text}                            
                                    <Link className="my-2 ml-2" to={ to }><span style={{textDecoration:"underline", /*color:"red",*/ fontWeight:"bold"}}>Read More...</span></Link>
                                    </p>
                            </div>
                            <span style={{fontSize: "small", color: "grey"}}>{moment(date).format('ddd, MMMM DD, YYYY')}</span>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    render(){
        return(
            <div className="mb-5 blog-list">
                {this.renderItem(
                    'Why do doctors still use fax technology and what are the problems with faxing?',
                    'Fax (short for facsimile) was a popular technology in the 1990s. It has been mostly replaced by email and other internet technologies. However, faxing still persists in health care in British Columbia. Why is this the case.',
                    '/blog/post-2',
                    moment('2022-05-16').toString()
                )}
                {this.renderItem(
                    <>There has been a shift to virtual medical care in British Columbia. </>, 
                    <React.Fragment>
                        It is now possible for one to refill medications using <a href="http://www.instore.askmedication.com">Instore.AskMedication.com</a>. Which of my medications may I refill online? The easiest prescriptions to refill online are those that do not require much longitudinal monitoring.
                        </React.Fragment>, 
                    '/blog/post-1',
                    moment('2022-05-16').toString()
                )}
            </div>
        )
    }

}

const temp = connect()(Blog);
export {temp as Blog}