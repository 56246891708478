import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isPatient } from '../../_helpers/authorized';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogActions } from '@material-ui/core';
import config from '../../config';
import { userAction } from '../../_actions';
import { getAllPharmacy } from '../../_actions/pharmacyOscar.action';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        const { isFirstLoginShow1 } = this.props.Person;

        this.state = {
            msgError: null,
            msgSuccessMedicalInfo: false,
            isFirstLoginShow1,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getAllPharmacy());
    }


    handleClose = () => {
        this.setState({isFirstLoginShow1:true});
        const { dispatch, Person } = this.props;
        const personId = Person._id;
        dispatch(userAction.updateIsFirstLogInShowUp(personId));
    }
    renderFirstLoginPopUp() {
        const { isFirstLoginShow1 } = this.state;
        
       return <Dialog
           fullWidth={true}
           maxWidth="md"
           open={!isFirstLoginShow1}
       >
           <DialogTitle className="text-center">
                       <h3>Welcome to Instore.AskMedication.com
                       <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                            <i className="fa fa-close mx-2"></i>
                        </button>
                        </h3>
           </DialogTitle>
           <DialogContent >

                <div style={{fontSize:"20px"}}>
                <p>
                    Our website allows you to request the re-prescription of your chronic medications.
                    Please note that we cannot prescribe medications that are controlled or treat infectious diseases.
                    Furthermore, our services require payment.
                </p>

                <p>
                    If you are uncertain of whether you should take a medication or not, please book a phone or
                    in-person appointment with a doctor at the Richmond In-Store Medical Clinic by navigating to <a href={config.appointmentHost}>Instoremed.com</a>.
                </p>

                <p>If you would like to check whether your medication is available through Instore.AskMedication.com, click
                <span className="menu-text"><Link  to="/medication-search"> "Here"</Link>. Medications that are crossed out in red cannot be refilled through Instore.AskMedication.com.
                </span>
                    
                 </p>
               </div>
           </DialogContent>
           <DialogActions className="d-flex m-3">
               <button className="btn btn-primary mr-2 mt-4 " onClick={this.handleClose}>OK</button>
               {/* <button className="btn btn-outline-secondary mr-2 mt-4 btn-block" onClick={() => this.setState({ isSentToEmrPopUp: false})}>Cancel</button>
               <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                            <i className="fa fa-close mx-2"></i>
                        </button> */}
           </DialogActions>
       </Dialog>
   }

    renderPatientPanel() {
        const { noMedicalInfo, noSleepInfo } = this.props;

        return (
            <div>
                {noMedicalInfo &&
                    <div className="alert alert-warning" role="alert">
                        Please add your medical information to unlock the other features.
                    </div>
                }
                <div className='row dashboard'>
                <div className='col-md-4'>
                        <Link to="/medicalInfo/edit" className="link-dashboard">
                            <div className='div-link-dashboard'>
                                <div className='container-icon-link-dashboard'>
                                    <img src={require('../../assets/img/medicalinfo.svg')} className='icon-link-dashboard' alt='Medical Info Icon' title='Medical Info Icon' />
                                </div>
                                {this.props.noMedicalInfo ? 'Enter ' : 'Update '}
                                medical information
                            </div>
                        </Link>
                    </div>

                    <div className='col-md-4'>
                        {this.props.noMedicalInfo ?
                            <Link to="/request-prescription/medicalinfo" className='link-dashboard link-disabled' onClick={(e) => e.preventDefault()}>
                                <div className='div-link-dashboard'>
                                    <div className='container-icon-link-dashboard'>
                                        <img src={require('../../assets/img/prescription.svg')} className='icon-link-dashboard' alt='Prescription Icon' title='Prescription Icon' />
                                    </div>
                                    Request a prescription
                                </div>
                            </Link>
                            :
                            <Link to="/request-prescription/medicalinfo" className='link-dashboard'
                                data-test-id="dashboard__request-prescription"
                            >
                                <div className='div-link-dashboard'>
                                    <div className='container-icon-link-dashboard'>
                                        <img src={require('../../assets/img/prescription.svg')} className='icon-link-dashboard' alt='Prescription Icon' title='Prescription Icon' />
                                    </div>
                                    Request a prescription
                                </div>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    {/* <h1 className="h2 h1-header">Dashboard</h1> */}
                </div>
                {isPatient() && this.renderPatientPanel()}
                {isPatient() && this.renderFirstLoginPopUp()}
                
            </div>
        );
    }
}


Dashboard.propTypes = {
    noMedicalInfo: PropTypes.bool
};


function mapStateToProps(state) {
    const { isFirstLoginShow1, user:{Person} } = state.authentication;
    const { noMedicalInfo } = state.medicalInfo;
     
 
    return {
        isFirstLoginShow1,
        Person,
        noMedicalInfo
    };
}


const temp = connect(mapStateToProps)(Dashboard);

export { temp as Dashboard };

