import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        let d = new Date();
        let year = d.getFullYear();

        return <React.Fragment> 
        {/* <div class="footer-container"> */}
            <section class="footer1" id="footer1-q">
            <div class="container">
                <div class="media-container-row content text-white">
                    {/* { process.env.REACT_APP_APP_TYPE == 'office' && <button onClick={() => {
                        throw new Error(`Test error:`);
                    }}> test </button> } */}
                    <div class="col-12 col-md-6 col-lg-3 footer1__col">
                        <h5 class="align-left widget-title mbr-semibold mbr-fonts-style display-7">
                            ABOUT US</h5>
                        <div class="line-wrap align-left">
                            <div class="line"></div>
                        </div>
                        <p class="mbr-text mbr-normal mbr-fonts-style display-4">AskMedication.com is based in Vancouver, British Columbia, Canada.&nbsp;<br /></p>
                        <div class="item">
                            <div class="card-img">
                                <span class="mbr-iconfont img1 mobi-mbri-map-pin mobi-mbri">
                                    <i class="fas fa-map-marker-alt"></i>
                                </span>
                            </div>
                            <div class="card-box">
                                <h4 class="item-title align-left mbr-fonts-style display-4">2nd Floor Superstore, 350 SE Marine Drive, Vancouver, BC V5X 2S5</h4>
                            </div>
                        </div>
                        <div class="item">
                            <div class="card-img">
                                <span class="mbr-iconfont img1 socicon-mail socicon">
                                    <i class="far fa-envelope"></i>
                                </span>
                            </div>
                            <div class="card-box">
                                <h4 class="item-title align-left mbr-fonts-style display-4">info@inStoreMed.com</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 footer1__col">
                        <h5 class="align-left widget-title mbr-semibold mbr-fonts-style display-7">
                            COMPANY</h5>
                        <div class="line-wrap align-left">
                            <div class="line"></div>
                        </div>

                        <div class="link">
                            <div class="card-img">
                                {/* <span class="mbr-iconfont img2 mobi-mbri-arrow-next mobi-mbri">
                                </span> */}
                                <span>{'>'}</span>
                            </div>
                            <div class="card-box">
                                <h4 class="link-title align-left mbr-fonts-style display-4">
                                    <Link to="about-us">About Us</Link>
                                </h4>
                            </div>
                        </div>
                        <div class="link">
                            <div class="card-img">
                                {/* <span class="mbr-iconfont img2 mobi-mbri-arrow-next mobi-mbri"></span> */}
                                <span>{'>'}</span>
                            </div>
                            <div class="card-box">
                                <h4 class="link-title align-left mbr-fonts-style display-4">
                                    <Link to="faq">FAQ</Link>
                                </h4>
                            </div>
                        </div>
                        <div class="link">
                            <div class="card-img">
                                {/* <span class="mbr-iconfont img2 mobi-mbri-arrow-next mobi-mbri"></span> */}
                                <span>{'>'}</span>
                            </div>
                            <div class="card-box">
                                <h4 class="link-title align-left mbr-fonts-style display-4">
                                    <Link to="blog">Blog</Link>
                                </h4>
                            </div>
                        </div>

                        <div class="link">
                            <div class="card-img">
                                {/* <span class="mbr-iconfont img2 mobi-mbri-arrow-next mobi-mbri"></span> */}
                                <span>{'>'}</span>
                            </div>
                            <div class="card-box">
                                <h4 class="link-title align-left mbr-fonts-style display-4">
                                    <Link to="price">Pricing</Link>
                                </h4>
                            </div>
                        </div>

                        <div class="link">
                            <div class="card-img">
                                {/* <span class="mbr-iconfont img2 mobi-mbri-arrow-next mobi-mbri"></span> */}
                                <span>{'>'}</span>
                            </div>
                            <div class="card-box">
                                <h4 class="link-title align-left mbr-fonts-style display-4">
                                    <Link to="about-us">
                                        Contact Us &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    </Link>
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 footer1__col">
                        <h5 
                            class="align-left widget-title mbr-semibold mbr-fonts-style display-7"
                        >
                            OUR SERVICES
                        </h5>
                        <div class="line-wrap align-left">
                            <div class="line"></div>
                        </div>
                        <div class="list">
                            <div class="list-item d-flex pb-3">
                                <img class="tips pr-2" alt="Mobirise" data-alt="Mobirise" 
                                    data-src={require("../../assets/images/01.jpg")}
                                    src={require("../../assets/images/01.jpg")}
                                />
                                <div class="content d-flex flex-column justify-content-center">
                                    <h4 class="link-wrap mbr-fonts-style display-4">PRESCRIPTIONS</h4>
                                    <h4 class="date-wrap mbr-fonts-style display-4">
                                        <Link to="registration">Prescription on Demand</Link>
                                    </h4>
                                </div>
                            </div>
                            {/* <div class="list-item d-flex mt-2">
                                <img class="tips pr-2" alt="Mobirise" data-alt="Mobirise" 
                                    data-src={require("../../assets/images/02.jpg")} 
                                    src={require("../../assets/images/02.jpg")}
                                />
                                <div class="content d-flex flex-column justify-content-center">
                                    <h4 class="link-wrap mbr-fonts-style display-4">SLEEP APNEA</h4>
                                    <h4 class="date-wrap mbr-fonts-style display-4">
                                        <Link to="registration">Sleep Apnea Assessments</Link>
                                    </h4>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 footer1__col">
                        <h5 class="align-left widget-title mbr-semibold mbr-fonts-style display-7">
                            OPENING HOURS</h5>
                        <div class="line-wrap align-left">
                            <div class="line"></div>
                        </div>
                        <div class="box-list mbr-fonts-style display-4">
                            <li class="clearfix">Submit requests 24/7
                                <div>Doctors message you:</div>
                                <div>7am to 10pm - 7 days a week</div>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section class="footer2 cid-s8uW5ATDOe" id="footer2-1s">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <p class="mbr-text links mbr-fonts-style display-4">
                                © Copyright { year } &nbsp;<Link to='/' style={{
                                    textDecoration: "underline"
                                }}>Askmedication.com</Link> All Rights
                                Reserved
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        {/* </div> */}
        </React.Fragment>
    }

}

const temp = connect()(Footer);
export { temp as Footer }