import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';


export default class RequestedPopup extends Component {
    constructor() {
        super();
    }

    isOfficeHour = () => {
        const currentMoment = moment();
        const hour = currentMoment.hour();
        // const day = currentMoment.day();

        return hour >= 9 && hour < 19;
    }

    render() {
        return <React.Fragment>
            <DialogTitle>
                Prescription requested successfully!
            </DialogTitle>
            <DialogContent >
                <div style={{ fontSize: "20px" }}>
                    {/* { this.isOfficeHour() 
                        ? <p>
                            You will need to speak with a doctor prior to picking up your medications at the pharmacy. Please call 604-409-4096 and ask to be connected with one of our doctors. If a doctor is not immediately available, you will be called back shortly. Please ensure that we have the correct phone number(s) on file by clicking your name on the top right area of this screen.
                        </p> : <p> 
                            Thank you for using <Link to="/">AskMedication.com</Link>. A doctor will call you back shortly. Please ensure that we have the correct phone numbers on file.
                        </p>
                    } */}
                    <p> 
                    A doctor will shortly send you a message through email. Please monitor the email you used to login to Instore.AskMedication.com.
                    </p>
                </div>
            </DialogContent>
        </React.Fragment>
    }
}