import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { history } from '../../_helpers';


class Post extends React.Component{
    constructor(props){
        super(props);

    }

    handleBack = () => {
        history.goBack();
    }

    renderHandleBack = () => {
        return(
        <div className="mb-3 d-flex justify-content-between">
        <button className="btn btn-outline-secondary" onClick={this.handleBack}>Back</button>
        </div>
        )}

    renderPost1 = () => {
        return(
            <div className="post-list__post-item">
                <section className="cid-s8sWR3CzRa post-list__post-item__title" id="info3-1b">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-12 col-md-12 align-left">
                                <span style={{color: "grey"}}>{moment('2022-05-16').format('MMMM DD, YYYY')}</span>
                                <h2 class="mbr-section-title align-left mbr-fonts-style mbr-bold display-2">There has been a shift to virtual medical care in British Columbia. It is now possible for one to refill medications using <a href="http://www.askmedication.com">AskMedication.com</a>. Which of my medications may I refill online?</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <hr style={{width: "80%"}}/>
                <section className="title1 cid-s8pHUssV0f post-list__post-item__content-item" id="title1-19">
                    <div className="container align-center">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="wrap d-flex flex-column align-items-center">

                                    <h1 className="mbr-section-title align-center mbr-fonts-style display-7">1)Prescriptions that may be refilled online</h1>
                                    <p className="mbr-text align-center pt-4 mbr-fonts-style display-4">The easiest prescriptions to refill online are those that do not require much longitudinal monitoring. Furthermore, it is important to keep in mind that a physical exam by a doctor is not possible when you refill your medications online.
                                <br />
                                        <br />Examples of medications that are amenable to being prescribed online include oral contraceptive pills (birth control). It is still a good idea to monitor your blood pressure when you are taking an oral contraceptive pill. You can purchase a blood pressure monitor at any pharmacy for this purpose. Allergy eye drops and nasal sprays can also be refilled online. However, it is a good idea to visit a doctor to have a physical exam about once per 12 months. For example, the doctor may want to look into your nostrils to see if any nasal polyps are present. Nasal polyps can develop from long standing allergies and can be treated by an ENT (Ear Nose and Throat) specialist.
                                <br />
                                        <br />While an online physician can also refill your asthma and eczema medications, it is important to remember that overuse of corticosteroid medications may cause side-effects. Inhaled cortico-steroid medications are often used for asthma and can cause thrush (oral fungal infection). Topical cortico-steroid medications such as hydrocortisone can cause thinning of the skin if used excessively. Therefore, corticosteroids should be used sparingly. At times, sending a photograph of the affected area by text message to your doctor will help monitor these conditions without needing to go in-person to a medical clinic.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="title1 cid-s8pJe5SySy post-list__post-item__content-item" id="title1-1a">
                    <div class="container align-center">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="wrap d-flex flex-column align-items-center">

                                    <h1 class="mbr-section-title align-center mbr-fonts-style display-7">2)Prescriptions that should not be refilled online
                                    </h1>
                                    <p class="mbr-text align-center pt-4 mbr-fonts-style display-4">It is preferable for you to have a longitudinal relationship with a physician who knows you well. However, at times this is not possible because your family physician is difficult to access, sick or on maternity leave, travelling or retired with no replacement.
                                        <br />
                                        <br />The AskMedication.com physician can refill some prescriptions that require longitudinal monitoring. Examples are medications to treat blood pressure, cholesterol and diabetes. However, it will be necessary for you to eventually get a hold of your family doctor in order to undergo blood testing and a physical exam. Should you not have a family doctor, the Richmond In-Store Medical Clinic can provide comprehensive care including in-person and phone consultations.
                                        <br />
                                        <br />There are College of Physicians and Surgeons of British Columbia regulations that physicians must follow with online prescriptions. For example, opiates ("narcotics" or "pain pills") and benzodiazepines ("sleeping pills") cannot be refilled except by your usual provider. This is for safety, especially given the current opiate crisis in our province. Stimulant prescriptions (such as amphetamines for ADHD) also cannot be prescribed online given their misuse potential.
                                        <br />
                                        <br />AskMedication.com should not be used to refill antibiotics. Usually, these medications require an assessment with your usual medical doctor including a physical examination and possible laboratory assessments.
                                        <br />
                                        <br />This is a brief overview of some concerns when you refill your medications online. If you have any further questions about the above, please feel free to contact us at info@inStoreMed.com.
                                        <br />
                                        <br />
                                        <br />Dr. Balint Budai
                                        <br />
                                        <br />Family Physician
                                        <br />
                                        <br />Richmond In-Store Medical Clinic</p>
                                    <div class="buttons-wrap w-100 mt-4">
                                    <button className="btn btn-outline-secondary" onClick={this.handleBack}>Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
)}
renderPost2 = () => {
    return(
        <div className="post-list__post">
            <section class="cid-s8sWR3CzRa post-list__post-item__title" id="info3-1b">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-12 align-left">
                            <span style={{color: "grey"}}>{moment('2022-05-16').format('MMMM DD, YYYY')}</span>
                            <h2 
                                class="mbr-section-title align-left mbr-fonts-style mbr-bold display-2"
                            >
                                Why do doctors still use fax technology and what are the problems with faxing?
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
            <hr style={{width: "80%"}}/>
            <section className="title1 cid-s8pHUssV0f post-list__post-item__content-item" id="title1-19">
                <div className="container align-center">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="wrap d-flex flex-column ">
                                <h1 className="mbr-section-title align-center mbr-fonts-style display-7">1) Why do doctors still use fax technology ?</h1>
                                <p className="mbr-text  pt-4 mbr-fonts-style display-4">Fax (short for facsimile) was a popular technology in the 1990s. It has been mostly replaced by email and other internet technologies. However, faxing still persists in health care in British Columbia. Why is this the case?</p>
                                <p className="mbr-text  pt-4 mbr-fonts-style display-4">Part of it has to do with provincial legislation:<a 
                                    target="_self"
                                    download="5078-HPA_Bylaws_Community.pdf"
                                    href={require("../../assets/files/5078-HPA_Bylaws_Community.pdf")}
                                >http://library.bcpharmacists.org/6_Resources/6-1_Provincial_Legislation/5078-HPA_Bylaws_Community.pdf</a></p>
                                <p className="mbr-text  pt-4 mbr-fonts-style display-4">Prescriptions transferred by email from doctors to pharmacies or patients will not be acceptable as per the above legislation. Certainly, there are privacy concerns, but the authenticity of an emailed prescription is also difficult to verify. Even if a doctor emails a prescription to a patient and they then print it out, the prescription will not be valid. Paper prescriptions require a wet signature to be filled. </p>
                                <p className="mbr-text  pt-4 mbr-fonts-style display-4">A solution is electronic prescribing (eprescribing). In eprescribing, prescriptions are sent to the cloud (internet central repository) and then any pharmacy is British Columbia can fetch the prescriptions. The patient would just have to present themselves to any pharmacy in British Columbia, show ID, and tell the pharmacist that a prescription was written from them. </p>
                                <p className="mbr-text  pt-4 mbr-fonts-style display-4">A few versions of eprescribing have been set up such as https://prescribeit.ca/. However, the problem is that not all pharmacies accept this technology. Different transfer systems would need to be set up for each pharmacy based on whether they accept eprescribing or not.</p>
                                <p className="mbr-text  pt-4 mbr-fonts-style display-4">AskMedication.com will continue sending prescriptions by fax until a province-wide eprescribing solution is devised. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="title1 cid-s8pJe5SySy post-list__post-item__content-item" id="title1-1a">
                <div class="container align-center">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="wrap d-flex flex-column ">
                                <h1 class="mbr-section-title align-center mbr-fonts-style display-7">2) What are the problems with faxing? </h1>
                                <p class="mbr-text  pt-4 mbr-fonts-style display-4">Faxing is not very logical as a prescription is created digitally by the doctor, then transformed to analog form in order to be sent through phone lines, and then the prescription is delivered to the pharmacy. The prescription is usually recreated in digital format or sometimes printed out on a sheet of paper. Faxes go over phone lines, so calls can disconnect, lines can be busy and the transmission of information can be poor, delayed or fail completely. There is also no good way to double check that a prescription was accurately delivered.</p>
                                <p class="mbr-text  pt-4 mbr-fonts-style display-4">When your prescription is faxed by AskMedication.com, you will receive a confirmation email stating that the prescription was transmitted to a particular pharmacy. However, we cannot be sure that the prescription transfer took place 100% correctly due to the inherent limitations of fax technology. Should you arrive at a pharmacy and the pharmacist cannot find your prescription, please look at your confirmation email to ensure that you have presented yourself to the right pharmacy. If you are at the right pharmacy then please email us at info@inStoreMed.com so that we can promptly rectify the situation.</p>
                                <p class="mbr-text  pt-4 mbr-fonts-style display-4">Please realise that doctors hate faxes and many of us are working towards exterminating this technology.</p>
                                <p class="mbr-text  pt-4 mbr-fonts-style display-4"><Link to='/'>AskMedication.com</Link></p>
                            </div>
                            <div class="buttons-wrap w-100 mt-4">
                                    <button className="btn btn-outline-secondary" onClick={this.handleBack}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

    render(){
        const postName = this.props.match.params.name;
        let post;
        if(postName == 'post-1'){
           post =  this.renderPost1();      
       } else if (postName == 'post-2'){
           post =  this.renderPost2();
       } 
       return(
           <div>
               {post}
           </div>)
        }
    }

const temp = connect()(Post);
export {temp as Post}