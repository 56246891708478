import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registrationAction } from '../../_actions';
import { AgreementDialog } from './AgreementDialog';

class PharmanetConsentDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    handleClose = checked => {
        const { dispatch } = this.props;
        dispatch(registrationAction.closePharmanetConsent(checked));
    };

    buildContent() {
        const today = new Date();
        const formatedDate = `${today.toLocaleString('en-CA', { month: 'long' })} ${today.getDate()}, ${today.getFullYear()}`
        return (
            <div className="mx-3">
                <div className="row mb-3">
                    <div className="col-12 title mb-3">
                        <div>PHARMANET</div>
                        <div>Patient Consent to Access PharmaNet</div>
                    </div>
                    <div className="col-12 font-weight-bold text-center">
                        MEDICAL PRACTICE <br/>
                        ACCESS TO PHARMANET AGREEMENT
                    </div>
                </div>
                <div className="section">
                    <div>
                        The Province of British Columbia has established the provincial pharmacy network and database known as "PharmaNet" pursuant to section 37 of the Pharmacists, Pharmacy Operations and Drug Scheduling Act, R.S.B.C. 1996, c. 363, and which may be continued pursuant to section 13 of the Pharmacy Operations and Drugs Schedule Act, S.B.C., 2003, c. 77 should it be proclaimed in force during the term of this Agreement.
                    </div>
                    <div>
                        <p>I, the individual utilizing the Instore.AskMedication.com website, authorize the following individuals with whom I interact through the Instore.Askmedication.com website:</p>
                        <p>1) Physicians, and persons directly supervised by them  </p>
                        <p>2) Nurse practitioners, and persons directly supervised by them</p>
                        <p>To:</p>
                        <p>Access my personal health information contained within PharmaNet</p>
                        <p>In order to: </p>
                        <p>1) Providing therapeutic treatment or care to me</p>
                        <p>2) Monitoring drug use by me.</p>
                    </div>
                    <div>
                         I understand that I can withdraw this consent by writing an email to info@inStoreMed.com.
                    </div>
                    <div>
                        Executed on { formatedDate }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { openPharmanetConsent } = this.props;

        return (
            <AgreementDialog
                open={openPharmanetConsent}
                title="PharmaNet Consent"
                content={this.buildContent()}
                onClose={(checked) => this.handleClose(checked)}
            />
        );
    }
}

PharmanetConsentDialog.propTypes = {
    dispatch: PropTypes.func,
    openPharmanetConsent: PropTypes.bool
};


function mapStateToProps(state) {
    let { openPharmanetConsent } = state.registration;

    if (openPharmanetConsent === undefined) {
        openPharmanetConsent = false;
    }

    return {
        openPharmanetConsent
    };
}

const temp = connect(mapStateToProps)(PharmanetConsentDialog);

export { temp as PharmanetConsentDialog };