import React from 'react';
import { connect } from 'react-redux';
// import scriptLoader from 'react-async-script-loader';

class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.email = "info@inStoreMed.com";
    }
    renderEmailLink = () => {
        return <a href={`mailto:${this.email}`}>{this.email}</a>;
    }

    render() {
        return <section className="header04 cid-s8nbXon4Jq about-us" id="header4-s">
            <div className="container-fluid">
                <div className="row align-items-center content-row">
                    <div className="col-lg-6 wrap-block about-us__contents">
                        <div className="contents">
                            <h2 className="mbr-subtitle mbr-fonts-style mbr-bold display-4">WHO WE ARE</h2>
                            <h1 className="mbr-section-title mbr-fonts-style mbr-bold display-2">About Us</h1>
                            <h3 className="mbr-section-text mbr-fonts-style mbr-bold boldSubtitle display-7 about-us__contents__sub-text">
                            Instore.AskMedication.com is based in Richmond, British Columbia, Canada.
                            </h3>
                            <h4 className="mbr-section-text mbr-fonts-style mbr-light display-4 about-us__contents__text"><div>We are an online medical service and you can always reach us at info@inStoreMed.com.
    </div><div>Our medical office assistants will be glad to discuss with you any concern that you may have.
    </div><div>If your medical needs are complex and cannot be solved by <a href="http://www.askmedication.com" target="_blank">AskMedication.com</a>, please visit our 2 physical locations:
    </div><div>1) South Vancouver Medical Clinic: <a href="http://www.southvanmed.com" target="_blank">SouthVanMed.com
    </a></div><div>2) Richmond in-Store Medical Clinic: <a href="http://www.instoremed.com" target="_blank">inStoreMed.com</a></div></h4>
                        </div>

                    </div>
                    <div className="col-lg-6 photo-split about-us__photo">
                        <div className="element-inner about-us__photo__flag">
                            {/* <span className="widget-icon mbr-iconfont imind-canada"></span> */}
                            <div className="widget-icon mbr-iconfont imind-canada">
                            <img alt="" title="" data-alt="" 
                                data-src={require("../../assets/images/canada-flag.png")} 
                                className="about-us__icon" 
                                src={require("../../assets/images/canada-flag.png")} 
                            />
                            </div>
                        </div>
                        <div className="element-outer about-us__photo__inner">
                        </div>
                        <div className="element-outer2 about-us__photo__outer">
                        </div>
                        <div className="image-wrap about-us__photo__pad">
                            <img alt="" title="" data-alt="" 
                                src={require("../../assets/images/mbr-1290x1024.png")} 
                                data-src={require("../../assets/images/mbr-1290x1024.png")} 
                                className="" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    }

}

// const withScript = scriptLoader(
//     ["../assets/web/assets/jquery/jquery.min.js",
//     "../assets/popper/popper.min.js",
//     "../assets/tether/tether.min.js",
//     "../assets/bootstrap/js/bootstrap.min.js",
//     "../assets/touchswipe/jquery.touch-swipe.min.js",
//     "../assets/formstyler/jquery.formstyler.js",
//     "../assets/formstyler/jquery.formstyler.min.js",
//     "../assets/datepicker/jquery.datetimepicker.full.js",
//     "../assets/smoothscroll/smooth-scroll.js",
//     "../assets/dropdown/js/nav-dropdown.js",
//     "../assets/dropdown/js/navbar-dropdown.js",
//     "../assets/theme/js/script.js",
//     "../assets/formoid.min.js"]
//   )(AboutUs)
  

const temp = connect()(AboutUs);
export { temp as AboutUs }