import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { profileAction, alertAction } from '../../_actions';
import { FileUploader, RequiredLabel, CustomImage, CountrySelect, ProvinceSelect, ErrorMsgField } from '../../components';
import { uploadBucket, getPersonId, isPatient } from '../../_helpers';
import { ProfileValidator } from '../../_validator';
import { profileConstant, msgConstant } from '../../_constants';

class HealthDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false
        };

        this.changeValue = this.changeValue.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    section = profileConstant.SECTION_HEALTH_DOC;

    /**
     * Get the value from the inputs form
     */
    changeValue = (event) => {
        const { dispatch, user } = this.props;

        user[event.target.name] = event.target.value;
        dispatch(profileAction.change(user));
    }

    changeBCCard = (event) => {
        const { dispatch, user } = this.props;

        if (event.target.value === 'No') {
            user.selectHealthCard = '';
        } else {
            user.selectHealthCard = undefined;
        }

        user.selectPassport = undefined;
        user.selectBCCard = event.target.value;
        this.updateHealthInfo();

        dispatch(profileAction.change(user));
    }

    changeHealthCard = (event) => {
        const { dispatch, user } = this.props;

        if (event.target.value === 'No') {
            user.selectPassport = '';
        } else {
            user.selectPassport = undefined;
        }

        user.selectHealthCard = event.target.value;
        this.updateHealthInfo();

        dispatch(profileAction.change(user));
    }

    changePassport = (event) => {
        const { dispatch, user } = this.props;

        user.selectPassport = event.target.value;
        this.updateHealthInfo();

        dispatch(profileAction.change(user));
    }

    updateHealthInfo() {
        const { user } = this.props;

        // if select not equal to Yes, then set the related value to undefined
        // only select Yes should have the value
        if (user.selectBCCard !== 'Yes') {
            user.BCCardNumber = undefined;
        }

        if (user.selectHealthCard !== 'Yes') {
            user.HealthCardNumber = undefined;
            user.HealthCardProvince = undefined;
        }

        if (user.selectPassport !== 'Yes') {
            user.PassportNumber = undefined;
            user.PassportCountryId = undefined;
        }
    }

    /**
     * Get the file on the input file
     */
    changeFile = (files) => {
        const { dispatch, user } = this.props;
        user.healthDocFile = files && files[0];
        dispatch(profileAction.change(user));
    }

    changeFileBack = (files) => {
        const { dispatch, user } = this.props;
        user.healthDocFileBack = files && files[0];
        dispatch(profileAction.change(user));
    }
    /**
     * validate the confirm field
     */
    validateConfirmField(field) {
        const { dispatch, user } = this.props;

        if (user[field] && user[`confirm${field}`] &&
            user[field] !== user[`confirm${field}`]) {
            user[`confirm${field}Error`] = true;
        } else {
            user[`confirm${field}Error`] = false;
        }

        dispatch(profileAction.change(user));
    }

    renderConfirmFieldError(field) {
        if (this.props.user[`confirm${field}Error`]) {
            return (
                <div className="confirm-error-div">
                    <label>{`Confirm ${field} doesn't match ${field}`}</label>
                </div>
            );
        }

        return '';
    }

    /**
     * Create the formData to send data and upload the file
     */
    createFormData() {
        const { user } = this.props;
        const formData = new FormData();

        formData.append('personId', getPersonId());

        if (user.BCCardNumber) {
            formData.append('BCCardNumber', user.BCCardNumber);
        }
        if (user.HealthCardNumber) {
            formData.append('HealthCardNumber', user.HealthCardNumber);
        }
        if (user.HealthCardProvince) {
            formData.append('HealthCardProvince', user.HealthCardProvince);
        }
        if (user.PassportNumber) {
            formData.append('PassportNumber', user.PassportNumber);
        }
        if (user.PassportCountryId) {
            formData.append('PassportCountryId', user.PassportCountryId);
        }

        formData.append('file', user.healthDocFile);

        return formData;
    }

    /**
     * Submit the form sending a post request to the API
     */
    handleSave = (event) => {
        event.preventDefault();
        const { dispatch, user } = this.props;

        const validator = new ProfileValidator();
        validator.healthDoc(user);

        if (validator.validate()) {
            const formData = this.createFormData();

            dispatch(profileAction.updateHealthDoc(formData));
        } else {
            dispatch(alertAction.error(msgConstant.PLEASE_CORRECT_ERRORS_BELOW, validator.errorMsgs));
        }
    }

    handleEdit() {
        const { dispatch } = this.props;
        dispatch(profileAction.editSection(this.section));
    }

    handleCancel() {
        const { dispatch } = this.props;
        dispatch(profileAction.editSection(undefined));
    }

    changeCheckbox = (event) => {
        const { dispatch, user } = this.props;

        user[event.target.name] = event.target.checked;
        dispatch(profileAction.change(user));
 
    }

    renderDisplay() {
        const { user } = this.props;
        const isOpen = false;
       if(isPatient()){
        return (
            <div>
                <div className="d-flex justify-content-between align-items-center">
                   <h4 className="my-3">Health Document</h4>
                   {isOpen && <div>
                        <button className="btn btn-outline-primary btn-sm" type="button" onClick={this.handleEdit}>Edit</button>
                    </div>}
                </div>
                {/* <p className="description mb-3">If you wish to modify information related to your BC Services Card, provincial health card or passport, please email us at <a href="mailto:info@inStoreMed.com?subject=Login Support for AskMedication.com">info@inStoreMed.com</a>. </p> */}
                {user.BCCardNumber &&
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">BC Services Card Number</label>
                            <div>{user.BCCardNumber}</div>
                        </div>
                        {/* <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">BC Services Card pictures</label>
                            {this.renderHealthDocImage()}
                        </div> */}
                    </div>
                }
                {user.HealthCardNumber &&
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Canadian health card number</label>
                            <div>{user.HealthCardNumber}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Province</label>
                            <div>{user.HealthCardProvince}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Health card picture</label>
                            {this.renderHealthDocImage()}
                        </div>
                    </div>
                }
                {user.PassportNumber &&
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Passport number</label>
                            <div>{user.PassportNumber}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Country</label>
                            <div>{user.PassportCountry}</div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <label className="title">Passport picture</label>
                            {this.renderHealthDocImage()}
                        </div>
                    </div>
                }
            </div>
        )} else {
            return null;
        }
    }

    renderHealthDocImage() {
        const { user } = this.props;
        const imageKey = `${uploadBucket.healthDocument}/${user.healthDocKey}`;
        if (user.BCCardNumber) {
            const imageBackKey = `${uploadBucket.healthDocument}/${user.healthDocBackKey}`;
            return <div className="d-flex flex-wrap">
                <CustomImage imageKey={imageKey} />
                <CustomImage imageKey={imageBackKey} />
            </div>
        }
            
        return <CustomImage imageKey={imageKey} />;
    }

    renderEdit() {
        const { edit } = this.props;

        return (
            <div style={{width: "100%"}}>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="my-3">B.C. Services Card (CareCard)</h4>
                    {!edit &&
                        <div>
                            <button className="btn btn-outline-secondary btn-sm mr-2" type="button" onClick={this.handleCancel}>Cancel</button>
                            <button className="btn btn-outline-success btn-sm" type="submit" onClick={this.handleSave}>Save</button>
                        </div>
                    }
                </div>
                {profileConstant.IS_OTHER_PROV_ALLOWED && this.renderSelectBCCard()}
                {this.renderBCCardFields()}
                {this.renderSelectHealthCard()}
                {this.renderHealthCardFields()}
                {this.renderSelectPassport()}
                {this.renderPassportFields()}
            </div>
        );
    }

    renderSelectBCCard() {
        const { user } = this.props;

        return (
            <div className="row">
                <div className="col-md-4 col-sm-6 mb-3">
                    <RequiredLabel htmlFor="HasBCCard">
                        Do you have a BC Services Card?
                    </RequiredLabel>
                    <select className="custom-select d-block w-100" id="HasBCCard" name="HasBCCard"
                        value={user.selectBCCard}
                        onChange={this.changeBCCard} required>
                        <option value="">Choose...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
        );
    }

    renderBCCardFields() {
        const { user} = this.props;

        if (
            (profileConstant.IS_OTHER_PROV_ALLOWED && user.selectBCCard === 'Yes') 
            || !profileConstant.IS_OTHER_PROV_ALLOWED
        ) {
            return (
                <React.Fragment>
                    <div className="custom-control custom-checkbox mb-3">
                    <input type="checkbox" className="custom-control-input" id="isPrivatePay" name="isPrivatePay"
                        checked={user.isPrivatePay || false} onChange={this.changeCheckbox} />
                    <label className="custom-control-label privatepay-text" htmlFor="isPrivatePay">
                        I don't have a BC Services Card ($100.00 CDN for each request of 1 to 3 prescriptions)
                    </label>
                    </div>

                  {!user.isPrivatePay && <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                            {/* <RequiredLabel htmlFor="BCCardNumber">
                              BC Services Card Number
                            </RequiredLabel> */}
                            <input className="form-control" id="BCCardNumber" name="BCCardNumber"
                                value={user.BCCardNumber || ''} onChange={this.changeValue} required 
                                data-test-id="health-document__bc-card"
                                placeholder="BC Services Card Number *"
                            />
                            <div className="invalid-feedback">
                            BC Services Card Number is required.
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                            {/* <RequiredLabel htmlFor="confirmBCCardNumber">
                                Confirm BC Services Card Number
                            </RequiredLabel> */}
                            <input className="form-control" 
                                id="confirmBCCardNumber" 
                                name="confirmBCCardNumber"
                                data-test-id="health-document__confirm-bc-card"
                                value={user.confirmBCCardNumber || ''} 
                                onChange={this.changeValue} 
                                onBlur={() => this.validateConfirmField('BCCardNumber')} 
                                required 
                                placeholder="Confirm BC Services Card Number *"
                            />
                            <div className="invalid-feedback">
                                Confirm BC Services Card Number is required.
                            </div>
                            <ErrorMsgField isShow={user['confirmBCCardNumberError']}>
                                Confirm BC Services Card Number doesn&apos;t match BC Services Card Number
                            </ErrorMsgField>
                        </div>
                    </div>}
                    {profileConstant.IS_BC_CARD_UPLOAD_ALLOWED && <div className="row">
                        <div className="col-md-12 mb-3">
                            <strong>Please take a picture of both sides of your BC Services Card and upload them.</strong>
                        </div>
                        <div className="col-md-12 mb-3">
                            <RequiredLabel htmlFor="BCCardPicture">
                                <strong>Front:</strong>
                            </RequiredLabel>
                            <FileUploader id="BCCardPicture" onChange={this.changeFile} accept="application/pdf, image/*" /> 
                        </div>
                        <div className="col-md-12 mb-3">
                            <RequiredLabel htmlFor="BCCardPictureBack">
                                <strong>Back:</strong>
                            </RequiredLabel>
                            <FileUploader id="BCCardPictureBack" onChange={this.changeFileBack} accept="application/pdf, image/*" />
                        </div>
                    </div>}
                </React.Fragment>
            );
        }

        return '';
    }

    renderSelectHealthCard() {
        const { user } = this.props;

        if (user.selectHealthCard !== undefined) {
            return (
                <div className="row">
                    <div className="col-md-4 col-sm-6 mb-3">
                        <RequiredLabel htmlFor="HasHealthCard">
                            Do you have a Canadian health card?
                        </RequiredLabel>
                        <select className="custom-select d-block w-100" id="HasHealthCard" name="HasHealthCard"
                            value={user.selectHealthCard}
                            onChange={this.changeHealthCard} required>
                            <option value="">Choose...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
            );
        }

        return '';
    }

    renderHealthCardFields() {
        const { user } = this.props;

        if (user.selectHealthCard === 'Yes') {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="HealthCardNumber">
                                Canadian health card number
                            </RequiredLabel>
                            <input className="form-control" id="HealthCardNumber" name="HealthCardNumber"
                                value={user.HealthCardNumber || ''} onChange={this.changeValue} required />
                            <div className="invalid-feedback">
                                Canadian health card number is required.
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="confirmHealthCardNumber">
                                Confirm Canadian health card number
                            </RequiredLabel>
                            <input className="form-control" id="confirmHealthCardNumber" name="confirmHealthCardNumber"
                                value={user.confirmHealthCardNumber || ''} onChange={this.changeValue} onBlur={() => this.validateConfirmField('HealthCardNumber')} required />
                            <div className="invalid-feedback">
                                Confirm Canadian health card number is required.
                            </div>
                            <ErrorMsgField isShow={user['confirmHealthCardNumberError']}>
                                Confirm Canadian health card number doesn&apos;t match Canadian health card number
                            </ErrorMsgField>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="HealthCardProvince">
                                Canadian health card - Province
                            </RequiredLabel>
                            <ProvinceSelect id="HealthCardProvince" name="HealthCardProvince"
                                value={user.HealthCardProvince || ''} onChange={this.changeValue} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <RequiredLabel htmlFor="HealthCardPicture">
                                Please take a picture of your Health Card and upload it
                            </RequiredLabel>
                            <FileUploader id="HealthCardPicture" onChange={this.changeFile} accept="application/pdf, image/*" />
                        </div>
                    </div>
                </div>
            );
        }

        return '';
    }

    renderSelectPassport() {
        const { user } = this.props;

        if (user.selectPassport !== undefined) {
            return (
                <div className="row">
                    <div className="col-md-4 col-sm-6 mb-3">
                        <label htmlFor="HasPassport" className="title">
                            Do you have a Passport?
                        </label>
                        <select className="custom-select d-block w-100" id="HasPassport" name="HasPassport"
                            value={user.selectPassport}
                            onChange={this.changePassport} required>
                            <option value="">Choose...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
            );
        }

        return '';
    }

    renderPassportFields() {
        const { user } = this.props;

        if (user.selectPassport === 'Yes') {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="PassportNumber">
                                Passport number
                            </RequiredLabel>
                            <input className="form-control" id="PassportNumber" name="PassportNumber"
                                value={user.PassportNumber || ''} onChange={this.changeValue} required />
                            <div className="invalid-feedback">
                                Passport number is required.
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="confirmPassportNumber">
                                Confirm Passport number
                            </RequiredLabel>
                            <input className="form-control" id="confirmPassportNumber" name="confirmPassportNumber"
                                value={user.confirmPassportNumber || ''} onChange={this.changeValue} onBlur={() => this.validateConfirmField('PassportNumber')} required />
                            <div className="invalid-feedback">
                                Confirm Passport number is required.
                            </div>
                            <ErrorMsgField isShow={user['confirmPassportNumberError']}>
                                Confirm Passport number doesn&apos;t match Passport number
                            </ErrorMsgField>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-3">
                            <RequiredLabel htmlFor="PassportCountryId">
                                Passport country
                            </RequiredLabel>
                            <CountrySelect id="PassportCountryId" name="PassportCountryId"
                                value={user.PassportCountryId || ''} onChange={this.changeValue} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <RequiredLabel htmlFor="PassportPicture">
                                Please take a picture of your Passport and upload it
                            </RequiredLabel>
                            <FileUploader id="PassportPicture" onChange={this.changeFile} accept="application/pdf, image/*" />
                        </div>
                    </div>
                </div>
            );
        }

        return '';
    }

    render() {
        const { user, edit, editSection } = this.props;

        if (user == undefined) return '';

        if (edit) {
            // edit mode, use in Registration page
            return this.renderEdit();
        } else {
            if (editSection === this.section) {
                return (
                    <form className="w-100" onSubmit={this.handleSave} noValidate>
                        {this.renderDisplay()}
                    </form>
                );
            } else {
                return this.renderDisplay();
            }
        }
    }
}


HealthDocument.propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object,
    edit: PropTypes.bool,
    editSection: PropTypes.string
};


function mapStateToProps(state) {
    const { user, editSection, status } = state.profile;

    if (user) {
        if (user.BCCardNumber) {
            user.selectBCCard = 'Yes';
        } else if (user.HealthCardNumber) {
            user.selectBCCard = 'No';
            user.selectHealthCard = 'Yes';
        } else if (user.PassportNumber) {
            user.selectBCCard = 'No';
            user.selectHealthCard = 'No';
            user.selectPassport = 'Yes';
        }

        // pre-filled
        if (status !== 'changed') {
            if (user.BCCardNumber && !user.confirmBCCardNumber) {
                user.confirmBCCardNumber = user.BCCardNumber;
            } else if (user.HealthCardNumber && !user.confirmHealthCardNumber) {
                user.confirmHealthCardNumber = user.HealthCardNumber;
            } else if (user.PassportNumber && !user.confirmPassportNumber) {
                user.confirmPassportNumber = user.PassportNumber;
            }
        }
    }

    return {
        editSection,
        user: { ...user }
    };
}

const temp = connect(mapStateToProps)(HealthDocument);

export { temp as HealthDocument };
