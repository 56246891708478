import React from 'react';
import { connect } from 'react-redux';


class FAQ extends React.Component{
    constructor(props){
        super(props);
        this.email = "info@inStoreMed.com";
        this.list = [{
                q: <React.Fragment>
                    Do I need a cell phone (mobile phone) to use Instore.AskMedication.com?
                </React.Fragment>,
                a: <React.Fragment>
                    It is best if you provide a cell phone that is able to receive text messages when registering for Instore.AskMedication.com. If you ever forget your password, you will be able to reset your password using this cell phone number. Therefore, please do not provide a landline as your cell phone number when you register for Instore.AskMedication.com.
                </React.Fragment>
            },
            {
                q: <React.Fragment>
                    I forgot my password. What do I do?
                </React.Fragment>,
                a: <React.Fragment>
                    Please use the “Forgot your password?” function found on the login page of <a href="https://Instore.askmedication.com/">Instore.AskMedication.com</a> to reset your password. This will require you to obtain a text message on the provided cell phone. If you did not provide the correct cell phone number or cannot receive text messages, you will not be able to reset your password on your own. You will have to get in touch with us at {this.email}.
                </React.Fragment>
            },
            {
                q: <React.Fragment>
                    What do I do if I have trouble signing up or logging into Instore.AskMedication.com?    
                </React.Fragment>,
                a: <React.Fragment>
                    Signing up and logging into Instore.AskMedication.com should be straightforward. However, if you encounter any problems, please email us at {this.renderEmailLink()}. It will be a pleasure to assist you. 
                </React.Fragment>
            },
            {   
                q: <React.Fragment>
                    Why do I need to provide my phone number(s) when registering for Instore.AskMedication.com?
                </React.Fragment>,
                a: <React.Fragment>
                    We ask you to provide us the best number(s) to contact you because a doctor will need to speak with you in order to authorize your prescription. Only after the doctor speaks to you can we send the prescription to the pharmacy.
                </React.Fragment>
            },
            {   
                q: <React.Fragment>
                    Can minors use Instore.AskMedication.com?
                </React.Fragment>,
                a: <React.Fragment>
                    Sorry, Instore.AskMedication.com is only available for users aged 19 years or above.
                </React.Fragment>
            },         
            {   
                q: <React.Fragment>
                    Can I refill any medication using Instore.AskMedication.com?
                </React.Fragment>,
                a: <React.Fragment>
                    Please note that a variety of medications cannot be refilled using Instore.AskMedication.com. Examples include controlled substances such as opiates (“pain pills”) or benzodiazepines (“sleeping pills”). The medications that cannot be refilled appear in red and crossed-out when you search medications on Instore.AskMedication.com. A doctor will also review your requested medications and may not be able to authorize them. We apologize in advance should this happen. Most of this time, this is done in order to ensure the safety of our users.
                </React.Fragment>
            },            
            {   
                q: <React.Fragment>
                    When will my prescription be sent to the pharmacy?
                </React.Fragment>,
                a: <React.Fragment>
                    A doctor will call you back on your phone shortly after you submit your medication request on Instore.AskMedication.com. After talking with you, the doctor may authorize your prescription. It will be usually immediately faxed to the pharmacy of your choice. Please note that faxes are sometimes not properly received by the pharmacy. This can happen when the receiving fax machine is busy. Should your prescription not be available at your pharmacy within 24 hours of your phone call with the doctor, please email us back at {this.renderEmailLink()} with your name and preferred pharmacy. We will gladly resend your prescription.
                </React.Fragment>
            },          
            {   
                q: <React.Fragment>
                    Can I just send text messages to the doctor to authorize my prescription?
                </React.Fragment>,
                a: <React.Fragment>
                    Sorry, at the present time text messaging is insufficient to authorize a prescription. You will need to speak with a doctor over the phone.
                </React.Fragment>
            },      
            {   
                q: <React.Fragment>
                    Can I receive urgent medical advice using this service?
                </React.Fragment>,
                a: <React.Fragment>
                    Should you have an acute or severe medical condition, we suggest you reach your usual medical provider. Should you not be able to do this, please call 911 or head to your nearest hospital emergency department.
                </React.Fragment>
            },            
            {   
                q: <React.Fragment>
                    Can I refill antibiotics?
                </React.Fragment>,
                a: <React.Fragment>
                    This service is mostly for refilling regularly taken medication. Exceptionally, especially in the context of COVID-19, we may be able to accommodate some other conditions such as those requiring antibiotics. However, this will only take place on a case by case basis.
                </React.Fragment>
            }
        ];
    }

    renderEmailLink = () => {
        return <a href={`mailto:${this.email}`}>{this.email}</a>;
    }

    renderText = () => {
        return (
            <div>
                <h1>Frequently Asked Questions (FAQ)</h1>
                <div className="content-container">
                    <div className="item">
                        <p className="title"><span className="question">1)</span>Do I need a cell phone (mobile phone) to use Instore.AskMedication.com?</p>
                        <p className="text">It is best if you provide a cell phone that is able to receive text messages when registering for Instore.AskMedication.com. If you ever forget your password, you will be able to reset your password using this cell phone number. Therefore, please do not provide a landline as your cell phone number when you register for Instore.AskMedication.com.</p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">2)</span>I forgot my password. What do I do?</p>
                        <p className="text">Please use the “Forgot your password?” function found on the login page of <a href="https://Instore.askmedication.com/">Instore.AskMedication.com</a> to reset your password. This will require you to obtain a text message on the provided cell phone. If you did not provide the correct cell phone number or cannot receive text messages, you will not be able to reset your password on your own. You will have to get in touch with us at info@inStoreMed.com.</p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">3)</span>
                    What do I do if I have trouble signing up or logging into Instore.AskMedication.com?
                    </p>
                        <p className="text">Signing up and logging into Instore.AskMedication.com should be straightforward. However, if you encounter any problems, please email us at info@inStoreMed.com. It will be a pleasure to assist you. </p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">4)</span>
                    Why do I need to provide my phone number(s) when registering for Instore.AskMedication.com?
                    </p>
                        <p className="text">
                    We ask you to provide us the best number(s) to contact you because a doctor may need to speak with you in order to authorize your prescription.
                    </p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">5)</span>Can I refill any medication using Instore.AskMedication.com?</p>
                        <p className="text">Please note that a variety of medications cannot be refilled using Instore.AskMedication.com. Examples include controlled substances such as opiates (“pain pills”) or benzodiazepines (“sleeping pills”). The medications that cannot be refilled appear in red and crossed-out when you search medications on Instore.AskMedication.com. A doctor will also review your requested medications and may not be able to authorize them. We apologize in advance should this happen. Most of this time, this is done in order to ensure the safety of our users.</p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">6)</span>
                    When will my prescription be sent to the pharmacy?
                    </p>
                        <p className="text">
                    A doctor will email you shortly after you submit your medication request on Instore.AskMedication.com. After getting in touch with you, the doctor may authorize your prescription. It will be usually immediately faxed to the pharmacy of your choice. Please note that faxes are sometimes not properly received by the pharmacy. This can happen when the receiving fax machine is busy. Should your prescription not be available at your pharmacy within 24 hours of your confirmation email, please email us back at info@inStoreMed.com with your name and preferred pharmacy. We will gladly resend your prescription.
                    </p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">7)</span>
                    Can I just send text messages to the doctor to authorize my prescription?
                    </p>
                        <p className="text">
                    Yes, most of the times email communication is sufficient to authorize a prescription. However, at times you may need to speak with a doctor over the phone or rarely come to the clinic for a physical exam prior to being able to authorize your prescription.
                    </p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">8)</span>
                    Can I receive urgent medical advice using this service?
                    </p>
                        <p className="text">
                    Should you have an acute or severe medical condition, we suggest you reach your usual medical provider. Should you not be able to do this, please call 911 or head to your nearest hospital emergency department.
                    </p>
                    </div>
                    <div className="item">
                        <p className="title"><span className="question">9)</span>
                    Can I refill antibiotics?
                    </p>
                        <p className="text">
                    This service is mostly for refilling regularly taken medication. Exceptionally, we may be able to accommodate some other conditions such as those requiring antibiotics. However, this will only take place on a case by case basis.
                    </p>
                    </div>



                </div>
            </div>

        )
    }

    renderContent = () => {
        return <React.Fragment>
            <h1>Frequently Asked Questions (FAQ)</h1>
            <div className="content-container">
                {this.renderList()}
            </div>
        </React.Fragment>
    }

    renderList = () => {
        return this.list.map(({q, a}, index) => {
            return this.renderItem(q, a, index + 1);
        })
    }

    renderItem = (q, a, number) => {
        return <div className="item">
            <p className="title">
                <span className="question">
                    {number})
                </span>
                {q}
            </p>
            <p className="text">
                {/* <span className="answer">Answer:</span> */}
                {a}
            </p>
        </div>
    }    

    render() {
        // <section className="faq-container">
        //     {this.renderContent()}
        // </section>
        return <section class="accordion1 cid-s8n7nQjTEu faq" id="accordion1-m">

    

    
        <div class="container">
            <div class="row faq__title">
                <div class="col-12 col-md-12 title-text">
                    <h3 class="mbr-section-subtitle mbr-fonts-style align-center mbr-bold display-4 text-center faq__title__main">WELCOME TO</h3>
    
                    <h2 class="mbr-fonts-style mbr-section-title align-center mbr-bold display-2 text-center faq__title__sub">
                        Frequently Asked <span>Questions</span>
                    </h2>
                    
                </div>
            </div>
            <div class="row faq__text">
                <div class="col-lg-12">
                    <div id="bootstrap-accordion_12" class="panel-group accordionStyles accordion" role="tablist" aria-multiselectable="true">
                        <div class="row">
                            <div class="col-lg-6">
                                
                                <div class="card faq__text__item">
                                    <div class="card-header" role="tab" id="headingOne">
                                        <a role="button" class="panel-title collapsed text-black pr-5" data-toggle="collapse" data-core="" href="#collapse1_12" aria-expanded="false" aria-controls="collapse1">
    
                                            <h4 class="mbr-fonts-style display-7">1. Do I need a cell phone (mobile phone) to use Instore.AskMedication.com?
                                             <div><span class="toggle-icon inactive sign mbr-iconfont"></span>
                                            </div>
                                            </h4>
    
                                        </a>
                                    </div>
                                    <div id="collapse1_12" class="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                        <div class="panel-body">
                                            <p class="mbr-fonts-style panel-text pr-5 display-4">It is best if you provide a cell phone that is able to receive text messages when registering for Instore.AskMedication.com. If you ever forget your password, you will be able to reset your password using this cell phone number. Please do not provide a landline as your phone number when you register for Instore.AskMedication.com.
                                            </p>                                
                                            <p class="mbr-fonts-style panel-text pr-5 display-4">Note that, if receiving text messages from Instore.AskMedication.com, you may be subject to additional charges by your mobile carrier, for which we shall not be responsible.
                                            <br/></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card faq__text__item">
                                    <div class="card-header" role="tab" id="headingTwo">
                                        <a role="button" class="collapsed panel-title text-black pr-5" data-toggle="collapse" data-core="" href="#collapse2_12" aria-expanded="false" aria-controls="collapse2">
                                            <h4 class="mbr-fonts-style display-7"><span class="toggle-icon inactive sign mbr-iconfont"></span>2. I forgot my password. What do I do?</h4>
                                        </a>
    
                                    </div>
                                    <div id="collapse2_12" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#bootstrap-accordion_12">
                                        <div class="panel-body">
                                            <p class="mbr-fonts-style panel-text pr-5 display-4">Please use the “Forgot your password?” link found on the login page of Instore.AskMedication.com to reset your password. This will require you to obtain a text message on the provided cell phone (charges from your carrier may apply). If you provide an incorrect cell phone number or cannot receive text messages, you will not be able to reset your password on your own and will have to get in touch with us at info@inStoreMed.com.
    <br/></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card faq__text__item">
                                    <div class="card-header" role="tab" id="headingThree">
                                        <a role="button" class="collapsed text-black panel-title pr-5" data-toggle="collapse" data-core="" href="#collapse3_12" aria-expanded="false" aria-controls="collapse3">
                                            <h4 class="mbr-fonts-style display-7"><span class="toggle-icon inactive sign mbr-iconfont"></span>3. What do I do if I have trouble signing up or logging into Instore.AskMedication.com?</h4>
                                        </a>
                                    </div>
                                    <div id="collapse3_12" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#bootstrap-accordion_12">
                                        <div class="panel-body">
                                            <p class="mbr-fonts-style panel-text pr-5 display-4">Signing up and logging into Instore.AskMedication.com should be straightforward. However, if you encounter any problems, please email us at info@inStoreMed.com. It will be a pleasure to assist you.<br/></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card faq__text__item">
                                    <div class="card-header" role="tab" id="headingFour">
                                        <a role="button" class="collapsed panel-title text-black pr-5" data-toggle="collapse" data-core="" href="#collapse4_12" aria-expanded="false" aria-controls="collapse4">
                                            <h4 class="mbr-fonts-style display-7"><span class="toggle-icon inactive sign mbr-iconfont"></span>
                                                4. Why do I need to provide my phone number(s) when registering for Instore.AskMedication.com?</h4>
                                        </a>
                                    </div>
                                    <div id="collapse4_12" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#bootstrap-accordion_12">
                                        <div class="panel-body">
                                            <p class="mbr-fonts-style panel-text pr-5 display-4">We ask you to provide us the best number(s) to contact you because a doctor may need to speak with you in order to authorize your prescription.<br/></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card faq__text__item">
                                    <div class="card-header" role="tab" id="headingFive">
                                        <a role="button" class="collapsed panel-title text-black pr-5" data-toggle="collapse" data-core="" href="#collapse5_12" aria-expanded="false" aria-controls="collapse5">
                                            <h4 class="mbr-fonts-style display-7"><span class="toggle-icon inactive sign mbr-iconfont"></span>
                                                5. Can minors use Instore.AskMedication.com?</h4>
                                        </a>
                                    </div>
                                    <div id="collapse5_12" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#bootstrap-accordion_12">
                                        <div class="panel-body">
                                            <p class="mbr-fonts-style panel-text pr-5 display-4">Sorry, Instore.AskMedication.com is only available for users aged 19 years or above.
    <br/></p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-lg-6">
                                
                                <div class="card faq__text__item">
                                    <div class="card-header" role="tab" id="headingOne">
                                        <a role="button" class="panel-title collapsed text-black pr-5" data-toggle="collapse" data-core="" href="#collapse7_12" aria-expanded="false" aria-controls="collapse7">
                                            <h4 class="mbr-fonts-style display-7"><span class="toggle-icon inactive sign mbr-iconfont"></span>6. Can I refill any medication using Instore.AskMedication.com?</h4>
                                        </a>
                                    </div>
                                    <div id="collapse7_12" class="panel-collapse noScroll collapse " role="tabpanel" aria-labelledby="headingOne" data-parent="#bootstrap-accordion_12">
                                        <div class="panel-body">
                                            <p class="mbr-fonts-style panel-text pr-5 display-4">Please note that a variety of medications cannot be refilled using Instore.AskMedication.com. Examples include controlled substances such as opiates (“pain pills”) or benzodiazepines (“sleeping pills”). The medications that cannot be refilled appear in red and crossed-out when you search medications on Instore.AskMedication.com. A doctor will review your requested medications, however, may not be able to authorize them. We apologize in advance should this happen. Most of the time, this is done in order to ensure the safety of our users.<br/></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card faq__text__item">
                                    <div class="card-header" role="tab" id="headingTwo">
                                        <a role="button" class="collapsed panel-title text-black pr-5" data-toggle="collapse" data-core="" href="#collapse8_12" aria-expanded="false" aria-controls="collapse8">
                                            <h4 class="mbr-fonts-style display-7"><span class="toggle-icon inactive sign mbr-iconfont"></span>7. When will my prescription be sent to the pharmacy?</h4>
                                        </a>
    
                                    </div>
                                    <div id="collapse8_12" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#bootstrap-accordion_12">
                                        <div class="panel-body">
                                            <p class="mbr-fonts-style panel-text pr-5 display-4">A doctor will email you shortly after you submit your medication request on Instore.AskMedication.com. After getting in touch with you, the doctor may authorize your prescription. If authorized, the prescription details will be usually immediately faxed to the pharmacy of your choice. Please note that faxes are sometimes not properly received by the pharmacy. This can happen when the receiving fax machine is busy. Should your prescription not be available at your pharmacy within 24 hours of your confirmation email, please email us back at info@inStoreMed.com with your name and preferred pharmacy. We will gladly resend your prescription.<br/></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card faq__text__item">
                                    <div class="card-header" role="tab" id="headingThree">
                                        <a role="button" class="collapsed text-black panel-title pr-5" data-toggle="collapse" data-core="" href="#collapse9_12" aria-expanded="false" aria-controls="collapse9">
                                            <h4 class="mbr-fonts-style display-7"><span class="toggle-icon inactive sign mbr-iconfont"></span>8. Can I just send text messages to the doctor to authorize my prescription?</h4>
                                        </a>
                                    </div>
                                    <div id="collapse9_12" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#bootstrap-accordion_12">
                                        <div class="panel-body">
                                            <p class="mbr-fonts-style panel-text pr-5 display-4">Yes, most of the time, email communication is sufficient to authorize a prescription. However, at times you may need to speak with a doctor over the phone or, in rare cases, come to the clinic for a physical exam prior to a doctor being able to authorize your prescription.

    <br/></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card faq__text__item">
                                    <div class="card-header" role="tab" id="headingFour">
                                        <a role="button" class="collapsed panel-title text-black pr-5" data-toggle="collapse" data-core="" href="#collapse10_12" aria-expanded="false" aria-controls="collapse10">
                                            <h4 class="mbr-fonts-style display-7"><span class="toggle-icon inactive sign mbr-iconfont"></span>
                                                9. Can I receive urgent medical advice using this service?</h4>
                                        </a>
                                    </div>
                                    <div id="collapse10_12" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#bootstrap-accordion_12">
                                        <div class="panel-body">
                                            <p class="mbr-fonts-style panel-text pr-5 display-4">Should you have an acute or severe medical condition, we suggest you reach out to your usual medical provider. Should you not be able to do this, please call 911 or head to your nearest hospital emergency department.
    <br/></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card faq__text__item">
                                    <div class="card-header" role="tab" id="headingFive">
                                        <a role="button" class="collapsed panel-title text-black pr-5" data-toggle="collapse" data-core="" href="#collapse11_12" aria-expanded="false" aria-controls="collapse11">
                                            <h4 class="mbr-fonts-style display-7"><span class="toggle-icon inactive sign mbr-iconfont"></span>10. Can I refill antibiotics?</h4>
                                        </a>
                                    </div>
                                    <div id="collapse11_12" class="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#bootstrap-accordion_12">
                                        <div class="panel-body">
                                            <p class="mbr-fonts-style panel-text pr-5 display-4">
                                            This service is primarily intended for refilling regularly taken medication. Exceptionally, we may be able to accommodate some other conditions such as those requiring antibiotics. However, this will only take place on a case-by-case basis.
                                           </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
            

        
    }

}

const temp = connect()(FAQ);
export {temp as FAQ}