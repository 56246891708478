import React from 'react';
import { connect } from 'react-redux';


class Price extends React.Component {
    constructor(props) {
        super(props);
        this.email = "info@inStoreMed.com";
    }
    renderEmailLink = () => {
        return <a href={`mailto:${this.email}`}>{this.email}</a>;
    }

    render() {
        return <section className="content1 cid-s8HdYgu9NI price" id="content3-1v">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 price__content">
                        <h2 className="mbr-fonts-style mbr-section-title align-center mbr-bold display-5">OUR PRICING</h2>
                        <p className="mbr-text pt-4 mbr-fonts-style display-7 price__content__text">

                           If you have a valid B.C. Services Card (CareCard): <br/>
                            1 Prescription: $20  <br/>
                            2 Prescriptions: $30 <br/>
                            3 Prescriptions: $40 <br/>
                            4 Prescriptions: $50 <br/>
                            5 Prescriptions: $60 <br/>

                            If you do not have a valid B.C. Services Card (CareCard):<br/>
                            1 Prescription: $120 <br/>
                            2 Prescriptions: $130 <br/>
                            3 Prescriptions: $140 <br/>
                            4 Prescriptions: $150 <br/>
                            5 Prescriptions: $160 <br/>
                        </p>

                    </div>
                    <div class="col-md-6">
                        <img data-alt="null" 
                            data-src={require('../../assets/images/10.jpg')} 
                            class="w-100" 
                            alt="null" 
                            src={require('../../assets/images/10.jpg')}
                        />
                    </div>  
                </div>
                <div className="row">
                   <p className="mbr-text pt-4 mbr-fonts-style display-7 price__content__text">
                   Please note that the maximum prescription length that we provide is 3 months. Furthermore, we may not be able to provide you the entire 3 months if a physical exam or further testing is required.
                   </p>
                </div>
            </div>
        </section>
    }

}

// const withScript = scriptLoader(
//     ["../assets/web/assets/jquery/jquery.min.js",
//     "../assets/popper/popper.min.js",
//     "../assets/tether/tether.min.js",
//     "../assets/bootstrap/js/bootstrap.min.js",
//     "../assets/touchswipe/jquery.touch-swipe.min.js",
//     "../assets/formstyler/jquery.formstyler.js",
//     "../assets/formstyler/jquery.formstyler.min.js",
//     "../assets/datepicker/jquery.datetimepicker.full.js",
//     "../assets/smoothscroll/smooth-scroll.js",
//     "../assets/dropdown/js/nav-dropdown.js",
//     "../assets/dropdown/js/navbar-dropdown.js",
//     "../assets/theme/js/script.js",
//     "../assets/formoid.min.js"]
//   )(AboutUs)
  

const temp = connect()(Price);
export { temp as Price}