import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import blue from '@material-ui/core/colors/blue';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import moment from 'moment';
import momentDurationFormatSetup from "moment-duration-format";

import { userAction } from '../../_actions';
import { alertAction } from '../../_actions';
import { history, appType, env } from '../../_helpers';
import { userConstant } from '../../_constants';
import config from '../../config';


momentDurationFormatSetup(moment);
const styles = () => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: 300
    },
    progress: {
        marginTop: 20,
        color: blue[500],
    }
});

const LIMIT_TIMES = 5;
const WAIT_SECONDS = 300; // 5 mins
class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            registered: false,
            recovered: false,
            msgErrorLogin: null,
            Email: '',
            Password: '',
            code: '',
            open2FADialog: false,
            countDown: 0
        };
        this.failTimes = 0;
        this.counter = 0;
        this.interval = null;

        this.submitForm = this.submitForm.bind(this);

        const { dispatch, alert } = this.props;

        history.listen(() => {
            // clear alert on location change if the alert is opened
            if (alert.isOpen) {
                dispatch(alertAction.clear());
            }
        });

        if (appType() === undefined) {
            // dev
            if (props.history.location.pathname === '/office-login') {
                this.role = userConstant.ROLE_DOCTOR;
            } else {
                this.role = userConstant.ROLE_PATIENT;
            }
        } else if (appType() === 'client') {
            this.role = userConstant.ROLE_PATIENT;
        } else if (appType() === 'office') {
            this.role = userConstant.ROLE_DOCTOR;
        }
    }

    componentDidMount() {
        let message = this.props.match.params.message;
        if (message === 'registered' && !this.state.msgErrorLogin) {
            this.setState({ registered: true });
        } else if (message === 'recovered' && !this.state.msgErrorLogin) {
            this.setState({ recovered: true });
        }

        if (
            appType() =='client'
        ) {
            window.location.href = config.southVanHost + "/login";
        } 
    }

    componentDidUpdate(prevProps) {
        if (this.props.verifymfa === true && prevProps.verifymfa === undefined) {
            this.setState({ open2FADialog: true });
        }
    }

    /**
     * Get the value from the inputs form
     */
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Submit the form sending a post request to the API
     */
    submitForm = async (event) => {
        event.preventDefault();

        const { dispatch } = this.props;

        // dispatch userAction login
        try {
            await dispatch(userAction.login(
                this.state.Email, this.state.Password, this.role, this.state.code
            ));
            this.counter = 0;
        } catch (error) {
            if (++this.failTimes >= LIMIT_TIMES) {
                this.props.alertDisplay(`You have failed too many login attempts. Please wait for ${moment.duration(WAIT_SECONDS, 'second').format('m [minutes]')} prior to trying again`);
                this.counter = WAIT_SECONDS;
                this.setState({
                    countDown: this.counter
                });
                this.interval = setInterval(() => {
                    if (this.counter-- > 0) {
                        this.setState({
                            countDown: this.counter
                        });
                        if (this.counter <= 0) {
                            clearInterval(this.interval);
                        }
                    }
                }, 1000);
            }
        }
    };

    renderHeading() {
        if (this.role === userConstant.ROLE_DOCTOR) {
            return (
                <h1 className="h3 mb-3 font-weight-normal">Office Sign In</h1>
            );
        } else {
            return (
                <h1 className="h3 mb-3 font-weight-normal">Sign In</h1>
            );
        }
    }

    onReCaptchaChange = (value) => {
        this.setState({
            reCaptcha: value
        });
    }

    renderReCaptcha() {
        return (
            <ReCAPTCHA
                sitekey={config.reCaptchaSiteKey}
                onChange={this.onReCaptchaChange}
            />
        );
    }

    renderButton() {
        const { loggingIn, classes } = this.props;
        const { reCaptcha } = this.state;

        if (loggingIn) {
            return (
                <CircularProgress className={classes.progress} />
            );
        } else {
            let disabled = this.state.countDown > 0;

            if (env() != 'auto')
                disabled = disabled || !reCaptcha; 

            let text = "Sign in";
            if (this.state.countDown > 0) {
                text += ` (after ${moment.duration(this.state.countDown, 'second').format("m [m], s [s]")})`;
            }
            return (
                <>
                    <button 
                        className="btn btn-lg btn-primary btn-block ml-0 login__submit-button" 
                        type="submit" disabled={disabled}
                        data-test-id="submit"
                    >
                        {text} 
                    </button>
                    <Link className="login__links__item" to={this.props.history.location.pathname === '/office-login' ? '/office-registration' : '/registration' }>Don&apos;t have an account? Sign up.</Link>
                    <Link to="/recovery" className="link-recovery login__links__item">Forgot your password?</Link>
                    <Link to="/confirm" className="link-recovery login__links__item">Log in fails? Try validate email here.</Link>
                </>
            );
        }
    }

    render2FAForm() {
        return (
            <div className="container">
                <div className="py-5 text-center">
                    <h2>2-Step Verification</h2>
                    {
                    appType() === 'office' ? 
                        <p className="lead">Please enter your 2FA code as shown in Google Authenticator and continue your login.</p>: 
                        <p className="lead">Please check your text message, and enter your verification code to continue your login.</p>
                    }
                </div>
                <div className="row">
                    <div className="col-12 mb-3 text-center"
                        data-test-id="verification-code__input"
                    >
                        <TextField
                            id="code"
                            label="Verification Code"
                            className="col-md-12"
                            type="tel"
                            name="code"
                            autoComplete="Email"
                            margin="normal"
                            variant="outlined"
                            value={this.state.code}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true }}
                            required
                        />
                        <div className="invalid-feedback">
                            Verification code is required.
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="btn-bar mt-4 text-center">
                        <button className="btn btn-lg btn-primary btn-block" type="submit"
                            data-test-id="verification-code__submit"
                        >Confirm</button>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="btn-bar mt-4 text-center">
                            If you require help logging in, or have changed your phone number, please email us at <a href="mailto:info@inStoreMed.com?subject=Login Support for AskMedication.com">info@inStoreMed.com</a>.
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (
            appType() =='client'
        ) {
            return <div/>
        } 
        const { classes } = this.props;
        return (
            this.state.open2FADialog
            ? <form className="form-signin" onSubmit={this.submitForm} noValidate>
                {this.render2FAForm()}
              </form>
            : <form className="form-signin login" onSubmit={this.submitForm} noValidate>
                {this.renderHeading()}
                <TextField
                    id="Email"
                    label="Email"
                    className={classes.textField}
                    type="email"
                    name="Email"
                    autoComplete="Email"
                    margin="normal"
                    variant="outlined"
                    value={this.state.Email}
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                    data-test-id="email"
                    required
                />
                <TextField
                    id="Password"
                    label="Password"
                    className={classes.textField}
                    type="password"
                    name="Password"
                    autoComplete="Password"
                    margin="normal"
                    variant="outlined"
                    value={this.state.Password}
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                    data-test-id="password"
                    required
                />
                {this.renderReCaptcha()}
                {this.renderButton()}
            </form>
        );
    }
}


Login.propTypes = {
    dispatch: PropTypes.func.isRequired,
    alert: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    classes: PropTypes.object,
    loggingIn: PropTypes.bool,
    verifymfa: PropTypes.bool
};


function mapStateToProps(state) {
    const { loggingIn, verifymfa } = state.authentication;
    const { alert } = state;

    return {
        loggingIn,
        alert,
        verifymfa
    };
}

const mapDispatchToProps = dispatch => {
    return {
        alertDisplay: (title = '') => dispatch(alertAction.error(title, [])),
        dispatch
    }
}

const temp = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));

export { temp as Login };