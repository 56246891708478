import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { messageAction } from '../../_actions';
import { getUser, getPersonId, isPatient } from '../../_helpers/authorized';
import IconButton from '@material-ui/core/IconButton';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import Tooltip from '@material-ui/core/Tooltip';
import { messageConstant } from '../../_constants';
import { formatDateTime, uploadBucket, getS3ImageUrl, formatDateTimeDisplayInEmr } from '../../_helpers';
import { FileUploader } from '../../components';
import { multilineText } from '../../_helpers';
import { CustomImage } from '../../components';
import { compose } from 'recompose';
import { noteService } from '../../_services';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';


const styles = () => ({
    progress: {
        marginTop: 20,
        color: blue[500],
    }
});


class ReplyFaxResend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen :true,
            isOpenPopUp: true,
        };
    }

    componentDidMount(){
        const prescriptionId = this.props.match.params.prescriptionId;
        const { dispatch } = this.props;
        dispatch(messageAction.getPrescriptionStatus(prescriptionId));
  
    }

    // componentDidUpdate(prevProps){
       
    //     if(prevProps.isReplied != this.props.isReplied){
    //         this.setState({isOpenPopUp: this.props.isReplied})
    //     }
    // }

    handleReply = () => {
        const { dispatch } = this.props;
        dispatch(messageAction.reply());
    }

    handleDiscard = () => {
         this.setState({isOpen:false});
    }

    handleChange = event => {
        const { dispatch, sendMessageObj } = this.props;

        sendMessageObj.message = event.target.value;

        // dispatch messageAction change
        dispatch(messageAction.change(sendMessageObj));
    };

    /**
     * Get the file on the input file
     */
    changeFile = (files) => {
        const { dispatch, sendMessageObj } = this.props;
        sendMessageObj.files = files;
        dispatch(messageAction.change(sendMessageObj));
    }

    handleSendReplyByLink = (event) => {

        event.preventDefault();
        const { dispatch, sendMessageObj } = this.props;
        let personId = this.props.match.params.personId;
        let id = this.props.match.params.msgId;
        const from = personId;
        
        const textToEmr = sendMessageObj.message + `\n\n[Patient message received using Instore.AskMedication.com on ${formatDateTimeDisplayInEmr(Date.now())}]`

        dispatch(messageAction.sendReplyByLink(id, from, sendMessageObj.message, sendMessageObj.files, textToEmr));
        this.setState({
            isOpen:false
        });
    }

    handleClose = () => {
        this.handleDiscard();
        window.close();

    }

    handlePopUpClose = () => {
        this.setState({
            isOpenPopUp:false
        })
        window.close();
    }

    handleExpiredPopupClose = () => {
        window.close();
    }

    renderMessage(from, date, message, files) {
        return (
            <>
                <div className="title">
                    <span className="person">
                        {(from || {}).FirstName} {(from || {}).LastName}
                    </span>
                    <span className="date">
                        {formatDateTime(date)}
                    </span>
                </div>
                <div className="content">
                    {multilineText(message)}
                </div>
                <br />
                <div className="d-flex">
                    {files && files.map((o, index) => {
                        const fileKey = `${uploadBucket.messaging}/${o._id}_${o.name}`;
                        if ((/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(o.name)) {
                            return <CustomImage key={index} imageKey={fileKey} />;
                        }
                        return <a href={getS3ImageUrl(fileKey)} download>
                            {o.name}
                        </a>
                    })}
                </div>
            </>
        );
    }

    renderReplyV2() {
        let personId = this.props.match.params.personId;
        let msgId = this.props.match.params.msgId;
        const { sendMessageObj, messageObj } = this.props;
        const { isOpen } = this.state;
        const displayMessageBox = messageObj ?
    <div className="container">
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={isOpen}
        >
            <DialogTitle>
               <h2>Reply to the doctor's message</h2> 
            </DialogTitle>
            <DialogContent>
        <div id="message">
        {/* <h3>
            {messageObj.subject}
        </h3> */}
        {this.renderMessage(messageObj.from, messageObj.createdAt, messageObj.message, messageObj.files)}
        </div>
        <form noValidate>
            <div className="form-group">
                <textarea className="form-control" id="message" name="message" rows="4" placeholder="message ..."
                    onChange={this.handleChange}
                    value={sendMessageObj.message}
                    maxlength="500"
                    autoFocus
                >
                </textarea>
                <br />
                <FileUploader multiple={true} onChange={this.changeFile} accept="application/pdf, image/*" />
            </div>
        </form>
            </DialogContent>
            <DialogActions> 
                
            <button className="btn btn-primary mr-2" onClick = {(e) =>this.handleSendReplyByLink(e)}>Send</button>
            <button className="btn btn-primary " onClick={this.handleDiscard} >Close</button>
            </DialogActions>
        </Dialog>
    </div>: "";
        return displayMessageBox;
        

    }

    renderReplyPopUP(){
        const { isOpenPopUp } = this.state;
        return(
        <div className="container">
                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={isOpenPopUp}
                >
                    {/* <DialogTitle>
                    You have already replied to this message
                    </DialogTitle> */}
                    <DialogContent>
                        <p>
                        You have already advised our front desk that your prescription was not received by your selected pharmacy. If you continue encountering difficulties, please email out customer service at info@inStoreMed.com.
                        </p>
                    </DialogContent>
                    <DialogActions>
                    <button className="btn btn-primary ml-2" onClick={this.handlePopUpClose} >Close</button>
                    </DialogActions>
                </Dialog>
            </div>
        )

    }

    renderReplyPopUP2(){

        const { isOpenPopUp } = this.state;
        return(
        <div className="container">
                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={isOpenPopUp}
                >
                    <DialogTitle>
                    Your request has been succesfully sent to our front desk.
                    </DialogTitle>
                    {/* <DialogContent>
                        <p>
                       Please wait for further instructions from your AskMedication.com doctor. If you have any concerns in the mean time, please email the AskMedication.com customer service at info@inStoreMed.com.
                        </p>
                    </DialogContent> */}
                    <DialogActions>
                    <button className="btn btn-primary ml-2" onClick={this.handlePopUpClose} >Close</button>
                    </DialogActions>
                </Dialog>
            </div>
        )

    }

    renderExpiredPopup(){

        // const { isOpenPopUp } = this.state;
        return(
        <div className="container">
                <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={true}
                >
                    <DialogTitle>
                    This function has expired. Please email info@southvanmed for assistance
                    </DialogTitle>
                    {/* <DialogContent>
                        <p>
                    Please wait for further instructions from your AskMedication.com doctor. If you have any concerns in the mean time, please email the AskMedication.com customer service at info@southvanmed.com.
                        </p>
                    </DialogContent> */}
                    <DialogActions>
                    <button className="btn btn-primary ml-2" onClick={this.handleExpiredPopupClose} >Close</button>
                    </DialogActions>
                </Dialog>
            </div>
        )

    }



    render() {
        const { isPrescriptionReplied, isPrescriptionExpired } = this.props;
        // let displayContent = null;
        // isPrescriptionReplied ? this.renderReplyPopUP():this.renderReplyPopUP2();

        // if(isPrescriptionExpired) displayContent = this.renderExpiredPopup();

        console.log(this.props)
        let displayContent = null;


        if(isPrescriptionReplied == true) {
            displayContent = this.renderReplyPopUP();
        } else if(isPrescriptionReplied == false){
            displayContent = this.renderReplyPopUP2();
        } else if(isPrescriptionExpired ==true){
            displayContent = this.renderExpiredPopup();
        } 

        return (
                 <div className="reply">
                 {displayContent}
                </div>    
        )
    }
}


ReplyFaxResend.propTypes = {
    dispatch: PropTypes.func.isRequired,
    sendMessageObj: PropTypes.object,
    classes: PropTypes.object,
};


function mapStateToProps(state) {
    let { isPrescriptionReplied,isPrescriptionExpired, sendMessageObj, isReplied, messageInfo} = state.message;

    if (sendMessageObj === undefined) {
        sendMessageObj = {
            message: ''
        };
    }
    return {
        isPrescriptionReplied,
        isPrescriptionExpired,
        sendMessageObj,
        isReplied,
        messageObj:messageInfo
    };
}


const temp = compose(
    connect(mapStateToProps),
    withStyles(styles)
)(ReplyFaxResend);

export { temp as ReplyFaxResend };

